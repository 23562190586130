import * as React from "react"

import { Link } from 'gatsby'
import { PropTypes } from "prop-types"
import Image from "./image"

const Gallery = ({title, items}) => {
  const images = items.reduce((p, c) => p.concat(c.images.map(image => { return { image, idx: items.indexOf(c)}; })), []);
    // Random, sort of..
    // .reduce((p, c) => Math.random() > .5 ? p.concat(c) : [c].concat(p), []);

  return (
    <section className="gallery-section"> 
      <header className="entry-header heading">
        <h2 className="entry-title">{title}</h2>
      </header>
      <div className="portfolio-gallery-section">
        {/* <div className="portfolio-gallery-menu">
          <ul>
            <li className="filter active" data-filter="*">Alle</li>
            {items.map((item, i) => (
              <li key={i} className="filter" data-filter={'.category-' + i}>{item.title}</li>
            ))}
          </ul>
        </div> */}
        <div className="portfolio-gallery-demo">
          {images.map((image, i) => (
            <Link to={image.image.localFile.publicURL} key={i} className={'single-gallery mix category-' + image.idx}>
              <div className="portfolio-single-gallery ">
                <div className="protfolio-image">
                  <Image
                    image={image.image.localFile.childImageSharp.gatsbyImageData}
                    alt={`${image.image.alt}`}
                    sizes={'(min-width: 1200px) 300px, (min-width: 1024px) 25vw, (min-width: 768px) 33vw, (min-width: 480px) 50vw, 100vw'}
                    />
                  <div className="gallery-information">
                    <i className="fa fa-search-plus"></i>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </section>
)
}

Gallery.propTypes = {
	title: PropTypes.string.isRequired,
  items: PropTypes.any,
  // items: PropTypes.arrayOf(PropTypes.objectOf({
  //   title: PropTypes.string,
  //   images: PropTypes.arrayOf(PropTypes.objectOf({
  //     image: PropTypes.string
  //   }))
  // })).isRequired
}

Gallery.defaultProps = {
  title: `Gallery`,
  items: []
}

export default Gallery
