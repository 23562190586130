import React from "react"
import { graphql } from "gatsby"
import ReactMarkdown from 'react-markdown'
import Layout from "../../components/layout"
import Services from "../../components/services"
import Gallery from "../../components/gallery"
import Seo from "../../components/seo"
import Properties from "../../components/properties"
import Promotional from "../../components/promotional"
import rehypeRaw from 'rehype-raw'
import Image from "../../components/image"

export const query = graphql`
  query PropertyQuery($slug: String!) {
    strapiProperty(slug: { eq: $slug }) {
      title
      description
      content
      type
      image {
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(width: 1920, height: 700)
          }
        }
      }
      images {
        title
        images {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(width: 800, height: 500)
            }
          }
        }
      }
      features {
        title
        waarde
        icoon
      }
      slug
    }

    strapiPagina {
      offer_promotional_sale
      offer_promotional_rent
      offer_promotional_sold
      offer_promotional_rented
    }
  }
`

const Property = ({ data }) => {
  const property = data.strapiProperty
  const seo = {
    metaTitle: property.title,
    metaDescription: property.description,
    shareImage: property.image,
    article: true,
  }

  const showImages = (property.images || []).length 
    && property.slug !== 'uw-pand-hier' 
    && (['sold', 'rented'].indexOf(property.type) === -1);

  const showFeatures = (property.features || []).length;

  if (typeof window !== `undefined`) {
    window.localStorage.setItem('LAST_PROPERTY', window.location.href);
  }

  return (
    <Layout active="aanbod" page={property.slug}>
      <Seo seo={seo} />
      <section> 
        <div className="featured-slider">
          <div className="slider-content">
            <div className="slider-image">
              <Image
                image={property.image.localFile.childImageSharp.gatsbyImageData}
                alt={`Afbeelding van ${property.title}`}
                sizes={'100vw'}
              />
            </div>
          </div>
        </div>
        <div className="slider-text" style={{ marginTop: 60, maxWidth: 800 }}>
          <h1>{property.title}</h1>
          {property.description && (<p style={{ marginTop: 10 }}>{property.description}</p>)}
        </div>
      </section>
      <section style={{ paddingTop: 20, paddingBottom: 80, borderBottom: showImages ? 'none' : 'solid 1px #a9a9a9' }}>
        <div className="container">
          <div className="row">
            <div className="custom-col-10 text-md">
              <ReactMarkdown rehypePlugins={[rehypeRaw]} children={property.content} />
            </div>
          </div>
        </div>
      </section>

      {(<Gallery title="Kijk eens rond" items={property.images} />)}

      {showFeatures && (<Services title="In een notendop" cols={4} services={property.features.map(feature => { return { icon: feature.icoon, title: feature.title, description: feature.waarde }})} />)}

      {property.type === 'sale' && (<Promotional 
        title="Interesse of meer informatie nodig?" 
        description={data.strapiPagina.offer_promotional_sale}
        link="/contact"
        linkText="Contacteer ons" />)}

      {property.type === 'rent' && (<Promotional 
        title="Interesse of meer informatie nodig?" 
        description={data.strapiPagina.offer_promotional_rent}
        link="/contact"
        linkText="Contacteer ons" />)}

      {property.type === 'sold' && (<Promotional 
        icon="key"
        title={data.strapiPagina.offer_promotional_sold} />)}

      {property.type === 'rented' && (<Promotional 
        icon="key"
        title={data.strapiPagina.offer_promotional_rented} />)}

      <Properties title="Meer uit het aanbod" types={['rent', 'sale']} items={3} without={[property.slug]} />
    </Layout>
    )
}

export default Property