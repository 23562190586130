import * as React from "react"
import { PropTypes } from "prop-types"
 
const Services = ({ title, services, cols, clickable }) => {
  let [active, setActive] = React.useState(Math.floor(Math.min(2, services.length) * Math.random()));

  return (
    <section className="service-section" data-cols={cols}>
      <div className="container">
        <header className="entry-header heading">
          <h2 className="entry-title">{title}</h2>
        </header>
        <div className="row">
          {services.map((service, i) => (
            <div key={service.title} className={clickable ? (i === active ? 'active clickable' : 'clickable') : ('custom-col-' + (12 / cols))}
              role="button"
              onClick={e => {
                e.preventDefault();
                setActive(i);
              }}>
              <div className="post">
                <div className="post-content-wrapper">
                  <span className="service-icon">
                    <i className={'fa fa-' + service.icon}></i>
                  </span>
                  <div className="post-content-wrap">
                    <header className="entry-header">
                      <h3 className="featured-title">{service.title}</h3>
                      <div className="entry-content" style={{ display: clickable ? 'none' : ''}}>
                        <p>{service.description}</p>
                      </div>
                    </header>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {clickable && services[active] && (
            <div className="extra" role="presentation">
              <h3 className="featured-title" style={{ marginBottom: 10 }}>{services[active].title}</h3>
              <p>{services[active].description}</p>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

Services.propTypes = {
	title: PropTypes.string.isRequired,
  services: PropTypes.any,
  // services: PropTypes.arrayOf(PropTypes.objectOf({
  //   icon: PropTypes.string,
  //   title: PropTypes.string,
  //   description: PropTypes.string
  // })),
  cols: PropTypes.number
}

Services.defaultProps = {
  title: ``,
  services: [],
  cols: 2,
  clickable: false
}

export default Services
